export const COGNITO_USER_ATTRIBUTES = {
  JUPYTERHUB_USERNAME: 'custom:jupyterhub_username',
  ORGANIZATION: 'custom:organization',
  PHONE_NUMBER: 'custom:phone_number',
};

export const COLUMN_DATA_TYPE_OPTIONS = [
  { label: 'Boolean', value: 'boolean' },
  { label: 'Date', value: 'date' },
  { label: 'Integer', value: 'integer' },
  { label: 'Float', value: 'float' },
  { label: 'String', value: 'string' },
];

export const COLUMN_MAPPING_POLICY_OPTIONS = [
  { label: 'Name Based', value: 'name-based' },
  { label: 'Positional Based', value: 'positional-based' },
];

export const DATASET_STATUSES = {
  ERRORED: 'errored',
  PENDING: 'pending',
  STANDARDIZED: 'standardized',
};

export const DATASET_TYPES = {
  SYSTEM_UPLOAD: 'system-uploaded',
  USER_UPLOAD: 'user-uploaded',
};

export const DATE_FORMAT_OPTIONS = [
  { label: 'YYYY-MM-DD', value: 'YYYY-MM-DD' },
  { label: 'YYYY-MM-DDTHH:MM:SS', value: 'YYYY-MM-DDTHH:MM:SS' },
  { label: 'MMDDYYYYHHMMSS', value: 'MMDDYYYYHHMMSS' },
  { label: 'MMDDYYYY', value: 'MMDDYYYY' },
  { label: 'MM/DD/YY', value: 'MM/DD/YY' },
  { label: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
  { label: 'MM-DD-YYYY', value: 'MM-DD-YYYY' },
  { label: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
  { label: 'DD-MM-YYYY', value: 'DD-MM-YYYY' },
  { label: 'YYYY/MM/DD', value: 'YYYY/MM/DD' },
];

export const DEFAULT_JUPYTERHUB_USERNAME = 'system';

export const DELIVERY_TYPE = {
  API: 'api',
  MANUAL_UPLOAD: 'manual-upload',
  SFTP: 'sftp',
};

export const DELIVERY_TYPE_OPTIONS = [
  { label: 'SFTP', value: DELIVERY_TYPE.SFTP },
  { label: 'API', value: DELIVERY_TYPE.API },
  // { label: 'Manual Upload', value: DELIVERY_TYPE.MANUAL_UPLOAD },
];

export const API_TYPES = {
  CASE_SETUP: 'case-setup',
  ENROLLMENT: 'enrollment',
  POLICY_STATUS: 'policy-status',
};

export const API_TYPES_OPTIONS = [
  { label: 'Case setup', value: API_TYPES.CASE_SETUP },
  { label: 'Enrollment', value: API_TYPES.ENROLLMENT },
  { label: 'Policy status', value: API_TYPES.POLICY_STATUS },
];

export const API_MODES = {
  CALLBACK: 'callback',
  EXPOSE: 'expose',
};

export const API_MODES_OPTIONS = [
  { label: 'Callback', value: API_MODES.CALLBACK },
  { label: 'Expose', value: API_MODES.EXPOSE },
];

export const API_METHODS = {
  // DELETE: 'delete',
  GET: 'GET',
  POST: 'POST',
  // PUT: 'put',
};

export const API_METHODS_OPTIONS = [
  { label: 'GET', value: API_METHODS.GET },
  { label: 'POST', value: API_METHODS.POST },
];

export const API_KEY_TYPES = {
  CLIENT_PROVIDED_PUBLIC_KEY: 'client',
  DEX_GENERATED_PRIVATE_KEY: 'dex',
};

export const API_KEY_TYPE_OPTIONS = [
  {
    label: 'Client Provided Public Key',
    value: API_KEY_TYPES.CLIENT_PROVIDED_PUBLIC_KEY,
  },
  {
    label: 'Dex generated Private Key',
    value: API_KEY_TYPES.DEX_GENERATED_PRIVATE_KEY,
  },
];

export const EXECUTION_STATUSES = {
  FAILED: 'failed',
  NOT_STARTED: 'not-started',
  RUNNING: 'running',
  SUCCESS: 'success',
};

export const FILE_FORMAT_OPTIONS = [
  {
    label: 'AVRO',
    mimeType: 'application/avro',
    supported: 'avro',
    value: 'avro',
  },
  { label: 'BBA', mimeType: 'text/plain', supported: 'txt', value: 'bba' },
  { label: 'CSV', mimeType: 'text/csv', supported: 'csv', value: 'csv' },
  {
    label: 'EDI - 834',
    mimeType: 'application/edi-x12',
    supported: 'edi',
    value: 'edi',
  },
  {
    label: 'JSON',
    mimeType: 'application/json',
    supported: 'json',
    value: 'json',
  },
  {
    label: 'PARQUET',
    mimeType: 'application/x-parquet',
    supported: 'parquet',
    value: 'parquet',
  },
  {
    label: 'TSV',
    mimeType: 'text/tab-separated-values',
    supported: 'tsv',
    value: 'tsv',
  },
  { label: 'TXT', mimeType: 'text/plain', supported: 'txt', value: 'txt' },
  {
    label: 'XLS',
    mimeType: 'application/vnd.ms-excel',
    supported: 'xls',
    value: 'xls',
  },
  {
    label: 'XLSX',
    mimeType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    supported: 'xlsx',
    value: 'xlsx',
  },
  { label: 'XML', mimeType: 'text/xml', supported: 'xml', value: 'xml' },
];

export const FILE_TYPE_OPTIONS = [
  { label: 'Full File', value: 'full-file' },
  { label: 'Incremental', value: 'incremental' },
];

export const FILENAME_TYPES = {
  PREDEFINED_DATE_FORMAT: 'preDefinedDateFormat',
  SCHEMA_COLUMNS: 'schemaColumn',
  STRING: 'string',
};

export const FILENAME_TYPE_OPTIONS = [
  { label: 'String', value: FILENAME_TYPES.STRING },
  { label: 'Schema column', value: FILENAME_TYPES.SCHEMA_COLUMNS },
  {
    label: 'Predefined date format',
    value: FILENAME_TYPES.PREDEFINED_DATE_FORMAT,
  },
];

export const IS_BROWSER = typeof window !== 'undefined' && window;

export const JSON_SCHEMA_FORMAT_OPTIONS = [
  { label: 'Currency ($xx.yy)', value: 'currency' },
  { label: 'Date-time (YYYY-MM-DDTHH:mm:ss+zone)', value: 'date-time' },
  { label: 'Date (YYYY-MM-DD)', value: 'date' },
  { label: 'Email (local-part@domain.com)', value: 'email' },
  { label: 'Hostname (xxx.yyy.zzz.lll)', value: 'hostname' },
  { label: 'Idn-email (international-format@domain.com)', value: 'idn-email' },
  { label: 'Idn-hostname (xxx.yyy.zzz.lll)', value: 'idn-hostname' },
  { label: 'Iri (Internationalized Resource Identifiers)', value: 'iri' },
  {
    label:
      'Iri-reference (Internationalized Resource Identifiers for uri-reference)',
    value: 'iri-reference',
  },
  { label: 'Json-pointer (/foo/bar)', value: 'json-pointer' },
  { label: 'Ipv4 (a.b.c.d)', value: 'ipv4' },
  { label: 'Ipv6 (a:b:c:d:e:f:g:h)', value: 'ipv6' },
  {
    label: 'Relative-json-pointer (relative to json-pointer)',
    value: 'relative-json-pointer',
  },
  { label: 'Time (HH:mm:ss+zone)', value: 'time' },
  { label: 'Uri ("dffdfdfdf" - physical resource)', value: 'uri' },
  { label: 'Uri-reference (URI / relative-ref)', value: 'uri-reference' },
  { label: 'Uri-template (template like RFC 6570)', value: 'uri-template' },
];

export const PAGINATION_PARAMS = {
  OFFSET: 'page',
};

export const PERMISSION_ACTIONS = {
  D: 'D',
  R: 'R',
  W: 'W',
  X: 'X',
};

export const PERMISSION_ACTIONS_MAP = {
  _: 'Disabled',
  R: 'Read',
  RD: 'Read & Download Errors',
  RDW: 'Read, Write & Download Errors',
  RW: 'Read & Write',
  RWX: 'Read, Write & Execute',
  RX: 'Read & Execute',
};

export const PERMISSION_RESOURCES = {
  DASHBOARD: 'dashboard',
  DATASETS: 'datasets',
  DATUMS: 'datums',
  PIPELINES: 'workflows',
  POLICIES: 'policies',
  SCHEMAS: 'schemas',
  TEMPLATES: 'templates',
  TRADING_PARTNERS: 'trading-partners',
  USER_AND_ROLE: 'user-and-role',
};

export const PIPELINE_INPUT_TYPES = {
  BOOLEAN: 'Boolean',
  COLUMN: 'Column',
  DATASET: 'Dataset',
  DICTIONARY: 'Dictionary',
  FLOAT: 'Float',
  INTEGER: 'Integer',
  NEW_COLUMN: 'NewColumn',
  S3Path: 'S3Path',
  SCHEMA: 'Schema',
  STRING: 'String',
};

export const PIPELINE_STEP_INPUT_TYPES = {
  ...PIPELINE_INPUT_TYPES,
  AGGREGATE: 'Aggregate',
  COLUMN_DICTIONARY: 'ColumnDictionary',
  COLUMN_LIST: 'ColumnList',
  COMPONENTS_LIST: 'ComponentsList',
  CUSTOM: 'Custom',
  DATE: 'Date',
  LIST: 'List',
  REVERSED_COLUMN_DICTIONARY: 'ReversedColumnDictionary',
  RULESET: 'RuleSet',
  TAGS: 'Tags',
};

export const PIPELINE_STEP_INPUT_TYPE_DEFAULT_VALUES = {
  [PIPELINE_STEP_INPUT_TYPES.BOOLEAN]: false,
  [PIPELINE_STEP_INPUT_TYPES.COLUMN]: '',
  [PIPELINE_STEP_INPUT_TYPES.COLUMN_DICTIONARY]: {},
  [PIPELINE_STEP_INPUT_TYPES.DATASET]: '',
  [PIPELINE_STEP_INPUT_TYPES.DICTIONARY]: {},
  [PIPELINE_STEP_INPUT_TYPES.INTEGER]: null,
  [PIPELINE_STEP_INPUT_TYPES.SCHEMA]: '',
  [PIPELINE_STEP_INPUT_TYPES.STRING]: '',
  [PIPELINE_STEP_INPUT_TYPES.TAGS]: {},
};

export const PIPELINE_STEP_STATES = {
  ARCHIVED: 'archived',
  FAILED: 'failed',
  RUNNING: 'running',
  SUCCESS: 'success',
  UPSTREAM_FAILED: 'upstream_failed',
};

export const PIPELINE_STEP_TYPES = {
  CASE_RULES: 'caserules',
  CLICK_ENROLL_CONVERTER: 'clickenrollconverter',
  COLUMN_MAPPER: 'columnMapper',
  EXPORT_DATA: 'exportData',
  NORMALIZER: 'normalizer',
  RUN_NOTEBOOK: 'runNotebook',
  SCHEMA_VERIFICATION: 'schemaVerification',
  STATUS_UPDATER: 'statusupdater',
  VALUE_MAPPER: 'valueMapper',
};

export const PIPELINE_TYPES = {
  DATA_EXCHANGE: 'dex',
};

export const REGEXES = {
  ALPHA_NUMERIC: '[a-z0-9-]+',
  UUID: '[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}',
  VERSION_NUMBER: '[0-9.]+',
};

export const SEARCH_PARAMS = {
  CREATED: 'created',
  DATASET_STATUS: 'datasetStatus',
  DATASET_TYPE: 'datasetType',
  EXECUTION_STATUS: 'executionStatus',
  IS_PUBLISHED: 'isPublished',
  NAME: 'name',
  PARTNER: 'partner',
  PIPELINE_ID: 'pipelineId',
  STARTED_BY: 'startedBy',
  STATUS: 'status',
  TAGS: 'tags',
  VALIDATION_STATUS: 'validationStatus',
};

export const SEARCH_TYPES = {
  ERROR_MESSAGE: 'errorMessage',
  NAME: 'name',
  STATUS: 'status',
  TAG: 'tag',
};

export const SORT_ORDERS = {
  ASC: 'asc',
  DESC: 'desc',
};

export const SORT_PARAMS = {
  ORDER_BY: 'orderBy',
  SORT_BY: 'sortBy',
};

export const VALIDATION_STATUSES = {
  FAILED: 'failed',
  NOT_INITIATED: 'not initiated',
  PENDING: 'pending',
  VALIDATED: 'validated',
};

export const RECORD_STATUSES = {
  ERROR: 'error',
  PASS: 'pass',
  PENDING: 'pending',
};

export const RECORD_STATUS_LABEL_MAP = {
  [RECORD_STATUSES.ERROR]: 'error',
  [RECORD_STATUSES.PENDING]: 'In Process',
  [RECORD_STATUSES.PASS]: 'Pass',
};

export const RECORD_TRANSACTIONTYPE = {
  CANCELLATION: 'Cancellation',
  ENDORSEMENT: 'Endorsement',
  ENROLLMENT: 'Enrollment',
};

export const VALIDATION_STATUS_LABEL_MAP = {
  [VALIDATION_STATUSES.FAILED]: 'Fail',
  [VALIDATION_STATUSES.NOT_INITIATED]: 'Not Initiated',
  [VALIDATION_STATUSES.PENDING]: 'In Progress',
  [VALIDATION_STATUSES.VALIDATED]: 'Pass',
};

export const VALUE_MAPPER_CATEGORY = {
  ACTION: 'action',
  AGGREGATE: 'aggregate',
  CONDITION: 'condition',
};

export const YES_NO_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

export const UUID_PREFIXES = {
  DATASET: 'spr:bz:ds::',
  PARTNER: 'spr:partner::',
  PIPELINE: 'spr:bz:pipeline::',
  SCHEMA: 'spr:bz:schema::',
  TEMPLATE: 'spr:bz:template::',
  USER: 'spr:user::',
  USER_ROLE: 'spr:bz:user-role::',
};

export const BAR_PADDING = 0.3;

export const DEFAULT_SCALE = 1;
export const DEFAULT_OPACITY = 1;
export const HOVER_SCALE = 1.08;
export const HOVER_OPACITY = 0.4;
export const END_OF_NAME_REGEX = /\.[^.]+$/;
export const DEFAULT_GRID_GAP = 16;

export const RULE_SECTIONS = {
  RULE_BUILDER: 'RuleBuilder',
  RULE_LIST: 'RuleList',
};

export const AUTHENTICATION_TYPE = {
  BASIC: 'basic',
  O_AUTH: 'OAuth',
};

export const AUTHENTICATION_TYPE_OPTIONS = [
  { label: 'Basic', value: AUTHENTICATION_TYPE.BASIC },
  { label: 'OAuth', value: AUTHENTICATION_TYPE.O_AUTH },
];

export const TIME_FRAME = {
  BIWEEKLY: 'biweekly',
  DAY: 'day',
  MONTH: 'month',
  QUARTER: 'quarter',
  WEEK: 'week',
};

export const TIME_FRAME_OPTIONS = [
  { label: 'Day', value: TIME_FRAME.DAY },
  { label: 'Week', value: TIME_FRAME.WEEK },
  { label: 'Biweekly', value: TIME_FRAME.BIWEEKLY },
  { label: 'Month', value: TIME_FRAME.MONTH },
  { label: 'Quarter', value: TIME_FRAME.QUARTER },
];

export const TIME_FRAME_MOMENT_RANGE = {
  [TIME_FRAME.BIWEEKLY]: [2, 'w'],
  [TIME_FRAME.DAY]: [1, 'd'],
  [TIME_FRAME.MONTH]: [1, 'M'],
  [TIME_FRAME.QUARTER]: [3, 'M'],
  [TIME_FRAME.WEEK]: [1, 'w'],
};

export const idleTimeOut = 1000 * 60 * 30;

export const USER_LAST_ACTIVE = 'userLastActive';

export const DELIVERY_DETAILS_ORDER_TYPE = {
  AUTHENTICATION_DETAILS: 'authenticationDetails',
  DELIVERY_DETAILS: 'deliveryDetails',
  DELIVERY_DETAILS_SFTP: 'deliveryDetailsSftp',
  FILESETUP: 'filesetup',
};

export const DELIVERY_DETAILS_ORDER_LIST = [
  DELIVERY_DETAILS_ORDER_TYPE.FILESETUP,
  DELIVERY_DETAILS_ORDER_TYPE.DELIVERY_DETAILS,
  DELIVERY_DETAILS_ORDER_TYPE.DELIVERY_DETAILS_SFTP,
  DELIVERY_DETAILS_ORDER_TYPE.AUTHENTICATION_DETAILS,
];
export const PUBLIC_ROUTES = {
  COMPLETE_SIGNUP: '/complete-signup',
  LOGIN: '/login',
  PASSWORD_RESET: '/password-reset',
};

export const LOCAL_STORAGE_ACTIONS = {
  ADD: 'add',
  EDIT: 'edit',
  REMOVE: 'remove',
};

export const SPECIAL_KEYS = {
  RULEOUTPUTS: 'ruleOutputs',
};

// Advance only constants
export const END_OF_STRING_REGEX = (ch) =>
  new RegExp(`${END_OF_NAME_REGEX}`.slice(1, -1).replaceAll('.', ch || '.'));

export const TEMPLATES_LIST = 'templates_list';

export const USER_ICONS = {
  user: 'user',
  userGroup: 'user',
};

export const USER_SEARCH_HEADER_DATA = {
  ['hybrid' || 'default']: [
    'Name',
    'No. of Users',
    'Role',
    'Trading Partners',
    'Status', // status not present in hybrid view
    '',
  ],
  userOnly: ['Name', 'No. of Users', 'Group', 'Status', 'Trading Partners', ''],
};

export const STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  INVITED: 'invited',
};

export const STATUS_COLORS = {
  [STATUS.ACTIVE]: 'success',
  [STATUS.INACTIVE]: 'gray5',
  [STATUS.INVITED]: `darkenOrange`,
};
